let devUrl = "http://192.168.1.5:8000";
let prodUrl = "https://api.m1.md";
export let baseUrl = prodUrl;
// export let baseUrl = devUrl;

// try to ping devUrl, if it's available, use it
if (process.env.NODE_ENV === "development") {
  $fetch(devUrl)
  .then((r) => {
    baseUrl = devUrl
    // console.log("Development API");
  })
  .catch((e) => {
    // console.log("Production API")
  });
}

function errorToast(){
  if (!import.meta.client) {
    return
  }
  const toast = useToast();
  toast.add({
    title: "A aparut o eroare, va rugam incercati mai tarziu",
    icon: "i-heroicons-information-circle",
    color: "red",
  });
}

export default function useApi(
  method: string,
  url: string,
  data: any = null,
  notify = true
): any {

  if (typeof url == "string") {
    url = baseUrl + url
  }

  // const store = useStore();

  return $fetch(url, {
    method: method as any,
    body: data,
    headers: {
      // Authorization: "Bearer " + store.token,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((r: any) => {
      if (r.error && notify) {
        errorToast();
      }
      return r;
    })
    .catch((e: any) => {
      if (notify) {
        errorToast();
      }

      if (!e || !e.response || !e.response.status) {
        return false;
      }
      // else if (e.response.status === 401 && catch401) {
      //   navigateTo(`/auth/login`);
      // }
      else if (e.response.status === 403) {
        console.log("403: Entity not enabled");
      } else if (e.response.status === 422) {
        console.log("422: Invalid data");
      }

      return false;
    });
}

export function useApiPublic(
  method: string,
  url: string,
  data: any = null,
): any {

  if (typeof url == "string") {
    url = baseUrl + url
  }

  return $fetch(url, {
    method: method as any,
    body: data,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((r: any) => {
      return r;
    })
    .catch((e: any) => {
      return false;
    });
}
